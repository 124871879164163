import MaxWidthWrapper from "../common/MaxWidthWrapper";
import React from "react";
import { secondaryBlockDataHandler } from "../../helper/api/secondaryBlock.api";
import SecondaryBlockType from "../../types/SecondaryBlock.type";
import SecondaryWidthWrapper from "../common/SecondaryWidthWrapper";
import ourTechnologyType from "../../types/ourTechnology.type";
import ourTechnologiesHandler from "../../helper/api/ourTechnologies.handler";

interface OurTechnologiesComponentProps {}

interface OurTechnologiesComponentProps {
  technologyAgnostic: SecondaryBlockType | undefined;
  allTechnology: Array<ourTechnologyType> | undefined;
}

const OurTechnologiesComponent: React.FC<OurTechnologiesComponentProps> = ({
  allTechnology,
  technologyAgnostic,
}) => {
  const technologyAgnosticData = secondaryBlockDataHandler(technologyAgnostic);
  const allTechnologyData = ourTechnologiesHandler(allTechnology);

  return (
    <MaxWidthWrapper>
      <SecondaryWidthWrapper>
        <div className={"py-[24px] md:py-[48px]"}>
          <div className={"flex flex-col-reverse lg:flex-row"}>
            {/*Description component*/}
            <div className={"flex-1"}>
              <div className={"flex justify-center lg:justify-start"}>
                <h1 className={"text-[18px] font-[600] md:text-[35px]"}>
                  <span className={"text-[#192E35]"}>
                    {`${technologyAgnosticData?.titleSection?.primaryTitle}\xa0`}
                  </span>
                  <span className={"text-primary"}>
                    {technologyAgnosticData?.titleSection?.secondaryTitle}
                  </span>
                </h1>
              </div>
              {/*Description Component*/}
              <div>
                <p
                  className={
                    "my-[16px] text-center text-[14px] text-[#3A4754] md:py-[24px] md:text-[25px] lg:text-left"
                  }
                >
                  {technologyAgnosticData?.titleSection?.primaryDescription}
                </p>
              </div>
              <div
                className={
                  "grid grid-cols-3 gap-y-[3rem] gap-x-[3rem] py-[24px] md:grid-cols-4 md:gap-y-[5rem]"
                }
              >
                {allTechnologyData?.length &&
                  allTechnologyData?.map((technology, index) => {
                    if (index < 12)
                      return (
                        <div
                          key={index}
                          className={"flex items-center justify-center"}
                        >
                          <div
                            className={
                              "h-[40px] w-[40px] md:h-[70px] md:w-[70px]"
                            }
                          >
                            <img
                              style={{
                                display: "block",
                                width: "100%",
                                height: "100%",
                                objectFit: "contain",
                              }}
                              src={technology.imageData.media}
                              alt={technology.imageData.altText}
                            />
                            <div className={"flex justify-center"}>
                              <p
                                className={
                                  "mt-[5px] text-sm font-semibold text-secondary"
                                }
                              >
                                {technology?.name}
                              </p>
                            </div>
                          </div>
                        </div>
                      );
                  })}
              </div>
            </div>
            {/*Image component*/}
            <div className={"flex-1"}>
              <img
                src={technologyAgnosticData?.imageData?.[0]?.media}
                alt={technologyAgnosticData?.imageData?.[0]?.altText}
              />
            </div>
          </div>
        </div>
      </SecondaryWidthWrapper>
    </MaxWidthWrapper>
  );
};
export default OurTechnologiesComponent;
